import 'sticky-kit/dist/sticky-kit';

const stickykitInit = () => {
  const viewport = $(window).width();
  const reservationHeader = $('#header-reservation').innerHeight();
  if (viewport > 576) {
    $('#sticky-wp').stick_in_parent({
      ...reservationHeader && {
        offset_top: reservationHeader + 30,
      },
    });
  } else {
    $('#sticky-wp').trigger('sticky_kit:detach');
  }
};

stickykitInit();

$(window).on('resize', () => {
  stickykitInit();
});
