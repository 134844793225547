import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';

$('.magnific-popup').each((_, elm) => {
  $(elm).magnificPopup({
    preloader: true,
    removalDelay: 500,
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true,
    },
    callbacks: {
      beforeOpen() {
        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        this.st.mainClass = this.st.el.attr('data-effect');
      },
    },
  });
});
