const faqClose = () => {
  $('.faq-item--content').hide();
  $('.faq-item').removeClass('open');
};

const faqToggle = () => {
  faqClose();
  $('.faq-item--button').on('click', (e) => {
    const { currentTarget } = e;
    const dataTarget = currentTarget.getAttribute('data-faq');
    const isOpen = $(`#${dataTarget}`).hasClass('open');
    faqClose();
    if (!isOpen) {
      $(`#${currentTarget.getAttribute('data-faq')} .faq-item--content`).toggle();
      $(`#${dataTarget}`).addClass('open');
    }
  });
};

faqToggle();
