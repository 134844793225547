const handleEqualColumns = () => {
  $('.equal-col-wp .equal-col-item').css('minHeight', 'auto');
  $('.equal-col-wp').each((_, elm) => {
    const group = $(elm).find('.equal-col-item');
    let tallest = 0;
    group.each((__, elmChild) => {
      const thisHeight = $(elmChild).innerHeight();
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    });
    group.css('minHeight', tallest + 5);
  });
};

const checkboxToggle = () => {
  $('.toggle-picker .label').on('click', (e) => {
    $(e.currentTarget).prev().find('.checkbox-toggle').trigger('click');
  });
};

const fileInput = () => {
  $('.file-picker--input').each((_, elm) => {
    $(elm).on('change', (e) => {
      const titleFile = e.target.files[0].name;
      $(e.currentTarget).next().find('span').text(titleFile);
    });
  });
};

handleEqualColumns();
checkboxToggle();
fileInput();

setTimeout(() => {
  handleEqualColumns();
}, 300);

$(window).on('resize', () => {
  handleEqualColumns();
});
