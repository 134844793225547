import select2 from 'select2';
import 'select2/dist/css/select2.css';

window.select2 = select2();

$('.selectpicker').each((_, elm) => {
  const placeholder = $(elm).data('placeholder');
  $(elm).select2({
    language: 'fr',
    placeholder,
    width: '100%',
  });
});
